import { onDomLoad, onChange, formatMoney } from "../utils";
import { Store } from "../store/Store";
import { remotePriceAccessor } from "../store/actions";

const store = Store.getInstance();
if (store.currentPage.template === "collection") {
  onDomLoad(async () => {
    const tag = store.client?.tag
    const productGrid = document.querySelector('.products.elements-grid.woodmart-products-holder.woodmart-spacing-20.products-spacing-20.pagination-more-btn.row.grid-columns-4')
    // if(store.currentPage.path === "/collections/3-piece-bundle") {
    //   document.querySelector('.shop-title-wrapper')?.firstChild?.remove();
    //   document.querySelector('.term-description')?.classList.add('custom-promo-description');
    //   //male heading 
    //   const jacketHeadingDivMale = document.createElement('div')
    //   jacketHeadingDivMale.classList.add('custom-promo-jacket-heading-div-male')
    //   const jacketHeadingMale = document.createElement('h1')
    //   jacketHeadingMale.classList.add('custom-promo-heading-h1')
    //   jacketHeadingMale.textContent='Male: Select One Jacket and Two Pants.'
    //   jacketHeadingDivMale.appendChild(jacketHeadingMale)
    //   productGrid?.insertBefore(jacketHeadingDivMale,productGrid.firstChild)

     
    //   // male Sharkskin
    //   const maleSharkskinDiv = document.createElement('div')
    //   jacketHeadingDivMale.classList.add('custom-promo-jacket-heading-div-male')
    //   const SharkskinHeadingMale = document.createElement('h2')
    //   SharkskinHeadingMale.textContent="Sharkskin"
    //   SharkskinHeadingMale.classList.add('custom-promo-heading-h1')
    //   maleSharkskinDiv.appendChild(SharkskinHeadingMale)
    //   productGrid?.insertBefore(maleSharkskinDiv,productGrid.children[1])

    //   // male Tropical

    //   const maleTropicalDiv =document.createElement('div')
    //   maleTropicalDiv.classList.add('custom-promo-jacket-heading-div-female')
    //   const TropicalHeadingMale = document.createElement('h2')
    //   TropicalHeadingMale.textContent="Tropical"
    //   TropicalHeadingMale.classList.add('custom-promo-heading-h1')
    //   maleTropicalDiv.appendChild(TropicalHeadingMale)
    //   productGrid?.insertBefore(maleTropicalDiv,productGrid.children[9])



    //   //female heading
    //   const jacketHeadingDivFemale = document.createElement('div')
    //   jacketHeadingDivFemale.classList.add('custom-promo-jacket-heading-div-female')
    //   const jacketHeadingFemale = document.createElement('h1')
    //   jacketHeadingFemale.classList.add('custom-promo-heading-h1')
    //   jacketHeadingFemale.textContent=' Female: Select One Jacket and Two Pants.'
    //   jacketHeadingDivFemale.appendChild(jacketHeadingFemale);
    //   productGrid?.insertBefore(jacketHeadingDivFemale,productGrid.children[17])

    //    // female Sharkskin
    //    const femaleSharkskinDiv = document.createElement('div')
    //   jacketHeadingDivFemale.classList.add('custom-promo-jacket-heading-div-female')
    //    const SharkskinHeadingFemale = document.createElement('h2')
    //    SharkskinHeadingFemale.textContent="Sharkskin"
    //    SharkskinHeadingFemale.classList.add('custom-promo-heading-h1')
    //    femaleSharkskinDiv.appendChild(SharkskinHeadingFemale)
    //    productGrid?.insertBefore(femaleSharkskinDiv,productGrid.children[18])
       
 
    //    // male Tropical
 
    //    const femaleTropicalDiv =document.createElement('div')
    //    femaleTropicalDiv.classList.add('custom-promo-jacket-heading-div-female')
    //    const TropicalHeadingFemale = document.createElement('h2')
    //    TropicalHeadingFemale.textContent="Tropical"
    //    TropicalHeadingFemale.classList.add('custom-promo-heading-h1')
    //    femaleTropicalDiv.appendChild(TropicalHeadingFemale)
    //    productGrid?.insertBefore(femaleTropicalDiv,productGrid.children[26])
      

    //   const viewAllProductDiv=document.createElement('div')
    //   viewAllProductDiv.classList.add('custom-promo-viewall-anchor-div')
    //   const newAnchor = document.createElement('a');
    //   newAnchor.classList.add('custom-promo-viewall-anchor')
    //   newAnchor.textContent = 'view all products';
    //   newAnchor.setAttribute('href', `${store.url}${store.client?.collection.storefrontPath}`);
    //   viewAllProductDiv.appendChild(newAnchor)
    //   productGrid?.appendChild(viewAllProductDiv)

    // productGrid?.removeChild(productGrid?.children[14])
    // productGrid?.removeChild(productGrid?.children[21])
    // productGrid?.removeChild(productGrid?.children[26])
    // productGrid?.removeChild(productGrid?.children[26])
    // productGrid?.removeChild(productGrid?.children[26])

    // }else if(tag=='AAL'|| tag=='ENV'||tag=='AALEN'||tag=='AALJS'){
    //   const viewOfferProductDiv=document.createElement('div')
    //   viewOfferProductDiv.classList.add('custom-promo-anchor-div')
    //   const newAnchor = document.createElement('a');
    //   newAnchor.classList.add('custom-promo-anchor')
    //   newAnchor.textContent = ' Go to 3-Piece Bundle Sale';
    //   newAnchor.setAttribute('href', `${store.url}/collections/3-piece-bundle`);
    //   viewOfferProductDiv.appendChild(newAnchor)
    //   productGrid?.insertBefore(viewOfferProductDiv,productGrid.firstChild)


    // }
    const [getVariantPrice] = await remotePriceAccessor();

    const alterPrices = () => {
      document.querySelectorAll(".product").forEach((element) => {
        const variantId = Number(element.getAttribute("data-id"));
        if (!variantId) return;
        const variantPrice = getVariantPrice(variantId);
        const moneyElement = element.querySelector(".money");
        if (moneyElement) {
          moneyElement.innerHTML = formatMoney(variantPrice);
        }
      });
    };
    alterPrices();
    const grid = document.querySelector(".wlm-content");
    if (grid) {
      onChange(grid, alterPrices);
    }
  });
}
