import { onDomLoad } from "../utils";
import { Store } from "../store/Store";
import _ from "lodash";

/**
 * This page will alter login and registration page only
 * 1. Remove registration block if `enableRegistration` is set to false client. Client will is taken from params
 */

const currentUrl = new URL(window.location.href);

const getClientFromUrl = () => {
  const companyName = currentUrl.searchParams.get("company")?.toLowerCase();
  if (companyName) {
    const store = Store.getInstance();
    return (store.storeClients || []).find((c) => _.snakeCase(c.name) === companyName);
  }
};

const removeRegisterBlock = () => {
  const currentClient = getClientFromUrl();
  if (currentClient?.enableRegistration === false) {
    document.querySelector(".col-register-text")?.remove();
  }
};

if (
  currentUrl.pathname.endsWith("/account/login") ||
  currentUrl.pathname.endsWith("/account/register")
) {
  onDomLoad(() => {
    removeRegisterBlock();
  });
}
