import { formatMoney, onDomLoad } from "../utils";
import { getCompanyIssueProducts } from "../store/actions";
import { Product } from "../interfaces/Product";
import { Store } from "../store/Store";
import { config } from "../config";

const renderProductCard = (product: Product) => {
  return `<div class="col-6 col-md-4 col-lg-3" style="margin-bottom: 15px;" >
    <div class="card product-card border-0">
      <a href="/products/${product.handle}">
        <figure>
          <img src="${
            product.productImages[0].src
          }" class="card-img-top" alt="" style="max-height: 280px; object-fit: contain">
          <div class="mask">
            <h5 class="card-title fs-4">${product.title}</h5>
            <div>${formatMoney(product.variants[0].price)}</div>
          </div>
        </figure>
        <div class="card-body">
        <h5 class="card-title fs-4" style="font-weight: normal">${product.title}</h5>
        </div>
      </a>
    </div>
  </div>`;
};

const renderFilters = () => {
  const issues = Store.getInstance().customer.companyIssues.filter((i) => i.balance);
  const filtersHtml = issues
    .map((i) => {
      return `<span class="filter-tag tag" style="padding-left: 15px">
          <a href="/pages/company-issue-products?product_type=${i.productType.value}">${i.productType.value}</a>
        </span>`;
    })
    .join(" ");
  return `<div class="filters d-flex justify-content-end" style="padding: 15px 0">
    <span class="filter-tag tag" style="padding-left: 15px"><a href="/pages/company-issue-products"><b>View All</b></a></span> ${filtersHtml}
  </div>`;
};

const renderGrid = (products: Product[]) => {
  const productTypeFilter = new URLSearchParams(window.location.search).get("product_type");
  const filterProductId = Store.getInstance().customer.companyIssues.find(
    (ci) => ci.productType.value === productTypeFilter
  )?.productType.id;
  const clientId =  Store.getInstance().client?.id || 0;
  const filteredProducts = filterProductId
    ? products.filter((p) => (p.productTypeId === filterProductId
        || (clientId === config.bartClient1Id 
          && filterProductId === config.bartClient1Product1.secondaryType 
          && p.productTypeId === config.bartClient1Product1.actualType 
          && p.id === config.bartClient1Product1.id )
        || (clientId === config.bartClient1Id 
          && filterProductId === config.bartClient1Product2.secondaryType 
          && p.productTypeId === config.bartClient1Product2.actualType 
          && p.id === config.bartClient1Product2.id )
        || (clientId === config.bartClient2Id 
          && filterProductId === config.bartClient2Product1.secondaryType 
          && p.productTypeId === config.bartClient2Product1.actualType 
          && p.id === config.bartClient2Product1.id )
        || (clientId === config.bartClient2Id 
          && filterProductId === config.bartClient2Product2.secondaryType 
          && p.productTypeId === config.bartClient2Product2.actualType 
          && p.id === config.bartClient2Product2.id )
        || (clientId === config.bartClient2Id 
          && filterProductId === config.bartClient2Product3.secondaryType 
          && p.productTypeId === config.bartClient2Product3.actualType 
          && p.id === config.bartClient2Product3.id )
      ))
    : products;
  const setFilteredProducts = filteredProducts.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.id === value.id 
    ))
  )
  const productsHtml = setFilteredProducts.map(renderProductCard).join(" ");

  return `<div class="shopy">
      <div class="container">
        <div class="row">
          ${renderFilters()}
        </div>
        <div class="row grid">
          ${productsHtml}
        </div>
      </div>
    </div>`;
};

const url = new URL(window.location.href);
if (url.pathname === "/pages/company-issue-products") {
  onDomLoad(async () => {
    const doc = document.querySelector(".page-container .rte");
    if (!doc) {
      throw Error("Page container for company issue not found");
    }
    const products = await getCompanyIssueProducts();
    const sortedProducts = products.map((product) => {
      //@ts-ignore
      const sortedProductImages = product.productImages.slice().sort((a, b) => {
        if (a.position === b.position) {
          //@ts-ignore
          return new Date(b.createdAt) - new Date(a.createdAt); // If positions are equal, sort by createdAt in descending order
        } else {
          return a.position - b.position; // Sort by position in ascending order
        }
      });
    
      return {
        ...product,
        productImages: sortedProductImages,
      };
    });
    
    doc.innerHTML = renderGrid(sortedProducts);
  });
}
