import { hideGlobalProgress, showGlobalProgress } from "../utils";
import { pullCheckout } from "../store/actions";
import { Checkout } from "../interfaces";
import { CheckoutReadyState } from "../interfaces/Checkout";
import * as Sentry from "@sentry/browser";

// 1 seconds
const POLLING_INTERVAL = 1000;
// 60 seconds
const MAX_POLLING_TIME = 180_1000;

const alertMessage =
  "There was an error processing you request, please try again. If it happens again please contact us at admin.dev@mandhuniforms.com";

const handler = async (checkoutId: number, epochStartTime: number): Promise<Checkout> => {
  const checkout = await pullCheckout(checkoutId);
  // Show error if checkout errored on server side
  if (checkout.readyState === CheckoutReadyState.errored) {
    Sentry.captureEvent({ message: "Checkout Errored", extra: { checkout } });
    alert(alertMessage);
    window.location.href = "/checkout";
    return Promise.reject();
  } else if (
    checkout.readyState === CheckoutReadyState.enqueued ||
    checkout.readyState === CheckoutReadyState.processing
  ) {
    return new Promise((resolve, reject) => {
      if (Date.now() < epochStartTime + MAX_POLLING_TIME) {
        setTimeout(
          () => handler(checkoutId, epochStartTime).then(resolve, reject),
          POLLING_INTERVAL
        );
      } else {
        Sentry.captureMessage("Max polling time elapsed, rejecting with error");
        throw Error("Max polling time elapsed, rejecting with error");
      }
    });
  } else {
    return checkout;
  }
};

export const pollCheckout = async (checkoutId: number, message?:string) => {
  message?
  showGlobalProgress(message):
  showGlobalProgress("DO NOT REFRESH YOUR SCREEN. IT MAY CAUSE ERROR WITH PAYMENT OR ORDER CANCELLATION.");
  Sentry.setTag("checkoutId", checkoutId);
  Sentry.addBreadcrumb({ message: "Polling start" });
  await handler(checkoutId, Date.now());
  Sentry.addBreadcrumb({ message: "Polling complete" });
  hideGlobalProgress();
};
