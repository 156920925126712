import {
  addCSS,
  disableElement,
  enableElement,
  formatMoney,
  hideGlobalProgress,
  memorizedQuerySelector,
  onChange,
  onDomLoad,
  showGlobalProgress,
} from "../utils";
import debounce from "lodash/debounce";
import {
  clearPaymentDetails as clearPaymentDetailsFromCheckout,
  createPaymentDetails,
  getCheckout,
  getCheckoutFromOrder,
  getPaymentOptions,
} from "../store/actions";
import { Store } from "../store/Store";
import { PageType } from "../enums/PageType";
import { Checkout, Customer } from "../interfaces";
import { PaymentGateway } from "../enums/PaymentGateway";
import { renderCheckout } from "./checkout.page";
import { AccountType } from "../enums/AccountType";
import { hasActiveCompanyIssues } from "./companyIssues";
import { pollCheckout } from "./pollCheckout";
import { CheckoutReadyState } from "../interfaces/Checkout";

const gatewaySelector = `[data-select-gateway='${process.env.GATEWAY_ID}']`;
const customGatewaySelector = `[data-select-gateway='${process.env.GATEWAY_ID}']`;
const gatewayDescriptionSelector = `payment-gateway-subfields-${process.env.GATEWAY_ID}`;
const duePriceSelector = ".payment-due__price";
const allotmentInputSelector = "#shopy-allotment";
const allotmentAvailabilitySelector = "#shopy-allotment-availability";
const payrollInputSelector = "#shopy-payroll";
const payrollAvailabilitySelector = "#shopy-payroll-availability";
const applyAllowancesBtnSelector = ".btn.apply-allowances";
const clearAllowancesBtnSelector = ".btn.clear-allowances";
const feedbackContainerSelector = ".feedback-container";
const gatewayPayNowButtonSelector = ".btn.apply-allowances";
const clearPaymentDetailsButtonSelector = ".btn.clear-allowances";
const allGatewaySelector = "[data-select-gateway]";
const completeOrderButtonSelector = "#continue_button";
const companyIssueCheckBoxSelector = "#shopy-company-issues";

const store = Store.getInstance();

let checkOutReady = false;

const showFeedback = (message: string, level: "primary" | "warning" | "danger") => {
  memorizedQuerySelector(
    feedbackContainerSelector
  ).innerHTML = `<div class="alert alert-${level}">${message}</div>`;
};

const clearFeedback = () => (memorizedQuerySelector(feedbackContainerSelector).innerHTML = "");

const disableCreditCardPayment = () => {
  console.log("disabling cc payment");
  const creditCardElement = memorizedQuerySelector("[data-gateway-name=credit_card]");
  disableElement(creditCardElement);
}

const applyPaymentDetails = async (e: Event) => {
  const checkout = (await getCheckout()) as Checkout;
  disableGatewayPayNowButton();
  // showGlobalProgress();
  let details;
  try {
    // @ts-ignore
    if (memorizedQuerySelector(companyIssueCheckBoxSelector)?.checked) {
      details = await createPaymentDetails(checkout.totalDue, 0, 0, true);
      await pollCheckout(checkout.id, "APPLYING M&H PAYMENT. DO NOT REFRESH YOUR SCREEN.");
    } else {
      const [allotment, payroll] = [allotmentInputSelector, payrollInputSelector].map((s) =>
        Math.round((Number((memorizedQuerySelector(s) as HTMLInputElement)?.value) || 0) * 100)
      );
      if (Number.isNaN(allotment) || Number.isNaN(payroll)) {
        enableGatewayPayNowButton();
        hideGlobalProgress();
        return showFeedback("Invalid inputs", "warning");
      }
      details = await createPaymentDetails(checkout.totalDue, allotment, payroll);
      await pollCheckout(checkout.id, "APPLYING M&H PAYMENT. DO NOT REFRESH YOUR SCREEN.");
    }
  } catch (e: any) {
    const message = e.response?.data?.message;
    if (message) {
      alert(message);
      showFeedback(message, "danger");
      hideGlobalProgress();
    }
    throw e;
  }
  const duePaymentDetail = details.find((pd) => pd.gateway === PaymentGateway.SHOPIFY);
  console.log({duePaymentDetail})
  
  duePaymentDetail
    ? location.reload()
    :   memorizedQuerySelector(completeOrderButtonSelector).click();
  return false;
};
// @ts-ignore
(window._SHOPY ||= {}).applyPaymentDetails = applyPaymentDetails;

const clearPaymentDetails = async () => {
  const checkout = (await getCheckout()) as Checkout;
  disableElement(memorizedQuerySelector(clearPaymentDetailsButtonSelector));
  // showGlobalProgress();
  await clearPaymentDetailsFromCheckout();
  await pollCheckout(checkout.id, "CLEARING M&H PAYMENT. DO NOT REFRESH YOUR SCREEN.");
  location.reload();
};
// @ts-ignore
(window._SHOPY ||= {}).clearPaymentDetails = clearPaymentDetails;

const displayCustomPaymentGateway = async () => {
  const gatewayContextElement = document.getElementById(gatewayDescriptionSelector);
  if (!gatewayContextElement) {
    throw Error("Gateway description element not found");
  }
  const customer = store.customer;
  const checkout = await getCheckout();

  const form = `
      <div class="pd-options row allotment-container" style="padding: 20px 10px;">
        <div class="col-sm-3 " style="align-self: center">
          <label for="shopy-allotment" class="col-form-label">Allotments:</label>
        </div>
        <div class="col-11 col-sm-7 input-group">
          <span class="input-group-text">$</span>
          <input type="number" name="shopy-allotment" id="shopy-allotment" class="form-control" aria-describedby="companyAllotment" value="0" min="0" readonly>
          <span class="input-group-text shopy-availability"><span id="shopy-allotment-availability" class="amount">$0</span>  Available</span>
        </div>
      </div>
      <div class="pd-options row payroll-container" style="padding: 20px 10px;">
        <div class="col-sm-3" style="align-self: center">
          <label for="shopy-payroll" class="col-form-label">Payroll:</label>
        </div>
        <div class="col-11 col-sm-7 input-group">
          <span class="input-group-text">$</span>
          <input type="number" name="shopy-payroll" id="shopy-payroll" class="form-control" aria-describedby="companyPayroll" value="0" min="0" step="any">
          <span class="input-group-text shopy-availability"><span id="shopy-payroll-availability" class="amount">$0</span>  Available</span>
        </div>
        <div class="col-sm-11 payroll-min-container" style="display: flex; justify-content: end">
          <small style="padding-right: 20px; padding-top: 5px;" >Payroll minimum: ${formatMoney(
            customer?.minimumPayrollTransactionAmount || 0
          )}</small>
        </div>
      </div>
        
       <div class="pd-options row form-check" style="padding: 0 10px 20px;">
        <div class="col-4 col-sm-3" style="align-self: center">
          <label for="shopy-company-issues" class="col-form-label form-check-label">Company Issue:</label>
        </div>
        <div class="col-6 input-group d-block company-issue-block">
          <input class="form-check-input ml-0" type="checkbox" id="shopy-company-issues" style="margin-left: 0">
          <br/>
          <div class="company-issue-note"></div>
          </div>
        </div>
      </div>
      
      <div class="feedback-container"></div>
      `;

  const actionContainer = `
      <div class="action-container" style="text-align: right;">
       <div name="button" class="btn clear-allowances" title="Clear payment breakdown" style="padding: 10px 20px; background-color: #842029; display: none" onclick="return window._SHOPY.clearPaymentDetails()">
        <span class="btn__content">Clear</span>
        </div>
        <div name="button" class="btn apply-allowances" style="padding: 10px 20px;" onclick="return window._SHOPY.applyPaymentDetails()">
        <span class="btn__content">Apply M&H Funds</span>
        </div>
      </div>
    `;

  gatewayContextElement.innerHTML = `<div class="shopy fieldset container">${form} ${actionContainer}</div>`;
  if (
    !hasActiveCompanyIssues() &&
    !checkout?.paymentDetails?.length &&
    !checkout?.paymentDetails?.find((pd) => pd.accountType === AccountType.COMPANY_ISSUE)
  ) {
    const payrollInputChangeHandler = debounce((e) => {
      const payrollUsed = Math.round(Number((e.target as HTMLInputElement).value) * 100);
      const remaining = (store.customer?.payrollBalance as number) - payrollUsed;
      memorizedQuerySelector(payrollAvailabilitySelector).innerHTML = formatMoney(remaining);

      const usedAllotment = Math.round(
        Number((document.querySelector(allotmentInputSelector) as HTMLInputElement).value) * 100
      );
      const totalDue = checkout?.totalDue as number;
      const dueAfterAllowance = totalDue - usedAllotment - payrollUsed;

      if (payrollUsed > 0 && payrollUsed < (customer?.minimumPayrollTransactionAmount as number)) {
        showFeedback(`Cannot use payroll amount less than minimum limit.`, "warning");
        return disableGatewayPayNowButton();
      }

      if (payrollUsed < 0 || payrollUsed > totalDue - usedAllotment) {
        disableGatewayPayNowButton();
        return showFeedback("Invalid input", "danger");
      } else if (usedAllotment === 0 && payrollUsed === 0) {
        disableGatewayPayNowButton();
      } else if (dueAfterAllowance === 0) {
        clearFeedback();
        enableGatewayPayNowButton();
      } else {
        if (dueAfterAllowance < Number(store.currentPage.checkout.shipping_fees)) {
          showFeedback(
            `You cannot split shipping with M & H payment methods but can pay entire amount with M & H payment methods.`,
            "warning"
          );
          disableGatewayPayNowButton();
        } else {
          showFeedback(
            `You can pay ${formatMoney(dueAfterAllowance)} from other payment modes.`,
            "primary"
          );
          enableGatewayPayNowButton();
        }
      }
    }, 300);

    const payrollInputElement = memorizedQuerySelector(payrollInputSelector);
    ["keyup", "change"].forEach((event) =>
      payrollInputElement.addEventListener(event, payrollInputChangeHandler)
    );
  }

  // Force disabled payroll input otherwise shopify keeps enabling it
  onChange(
    memorizedQuerySelector(payrollInputSelector),
    () => (payrollInputEnabled ? enablePayrollInput() : disablePayrollInput()),
    { attributes: true }
  );
};

let gatewayButtonEnabled = true;
const disableGatewayPayNowButton = () => {
  if (gatewayButtonEnabled) {
    disableElement(memorizedQuerySelector(gatewayPayNowButtonSelector));
    gatewayButtonEnabled = false;
  }
};

const enableGatewayPayNowButton = () => {
  if (!gatewayButtonEnabled && checkOutReady) {
    enableElement(memorizedQuerySelector(gatewayPayNowButtonSelector));
    gatewayButtonEnabled = true;
  }
};

let payrollInputEnabled = true;
const enablePayrollInput = () => {
  if(checkOutReady){
    (memorizedQuerySelector(payrollInputSelector) as HTMLInputElement).disabled = false;
    payrollInputEnabled = true;
  }
};

const disablePayrollInput = () => {
  (memorizedQuerySelector(payrollInputSelector) as HTMLInputElement).disabled = true;
  payrollInputEnabled = false;
};

let noDuesPostMAndHPayments = false; 
const prefillAllowances = async (checkout: Checkout) => {
  prefillCustomerAllowances(store.customer, checkout);
  if (checkout.paymentDetails.length) {
    //  Show existing payment details from checkout
    prefillCheckoutPaymentDetails(store.customer, checkout);
  }

  document.querySelectorAll('[name="checkout[payment_gateway]"]').forEach((e: Element) => {
    if (e instanceof HTMLInputElement) {
      const gateway = Number(e.value);
      if (e.checked && gateway === Number(process.env.GATEWAY_ID)) {
        disableCompleteOrderButton();
      }
    }
  });

  if(checkout.totalDue === 0){
    disableCreditCardPayment();
    enableCompleteOrderButton();
  }
  
};

const prefillCustomerAllowances = (customer: Customer, checkout: Checkout) => {
  const dueAmount = checkout.totalDue;
  const allotment = Math.min(customer.allotmentBalance, dueAmount);
  setInputFieldValue(allotmentInputSelector, allotment / 100);

  const remainingAllotment = customer.allotmentBalance - allotment;
  memorizedQuerySelector(allotmentAvailabilitySelector).innerHTML = formatMoney(remainingAllotment);
  const dueAfterAllotment = dueAmount - allotment;

  const payrollInputElement = memorizedQuerySelector(payrollInputSelector) as HTMLInputElement;
  const payrollMaxInput = Math.min(customer.payrollBalance, dueAfterAllotment);
  payrollInputElement.setAttribute("max", (payrollMaxInput / 100).toString());
  setInputFieldValue(payrollInputSelector, 0);

  if (
    dueAfterAllotment <= 0 ||
    dueAfterAllotment < customer.minimumPayrollTransactionAmount ||
    customer.payrollBalance === 0
  ) {
    memorizedQuerySelector(payrollAvailabilitySelector).innerHTML = formatMoney(customer.payrollBalance);
    disablePayrollInput();
  }
};

const prefillCheckoutPaymentDetails = (customer: Customer, checkout: Checkout) => {
  const mhDetails = checkout.paymentDetails.filter((pd) => pd.gateway === PaymentGateway.M_AND_H);

  mhDetails.forEach((pd) => {
    if (pd.accountType === AccountType.COMPANY_ISSUE) {
      const element = memorizedQuerySelector(companyIssueCheckBoxSelector);
      // @ts-ignore
      element.checked = true;
      disableElement(element);
      enableCompleteOrderButton();
      return;
    } else {
      const accountType = pd.accountType.toLowerCase();
      setInputFieldValue(eval(`${accountType}InputSelector`), pd.amount / 100);
      memorizedQuerySelector(eval(`${accountType}AvailabilitySelector`)).innerHTML = ( // @ts-ignore
        (customer[`${accountType}Balance`] - pd.amount) /
        100
      ).toFixed(2);
    }
  });

  disablePayrollInput();

  const dueAmount = checkout.paymentDetails.find(
    (pd) => pd.gateway === PaymentGateway.SHOPIFY
  )?.amount;

  if (dueAmount) {
    const ele = memorizedQuerySelector(".payment-due__price");
    ele.innerHTML = formatMoney(dueAmount);
    memorizedQuerySelector(".total-line-table").insertAdjacentHTML(
      "afterend",
      `<div class="shopy" style="padding-top: 10px"><div class="alert alert-primary">
              You can pay ${formatMoney(dueAmount)} from other payment methods.
              </div></div>`
    );
    showFeedback(`You can pay ${formatMoney(dueAmount)} from other payment modes.`, "primary");
  } else {
    memorizedQuerySelector(".total-line-table").insertAdjacentHTML(
      "afterend",
      `<div class="shopy" style="padding-top: 10px"><div class="alert alert-primary">
              Click "Complete Order" button to complete checkout and place this order.
              </div></div>`
    );
  }

  memorizedQuerySelector(applyAllowancesBtnSelector).style.display = "none";
  memorizedQuerySelector(clearAllowancesBtnSelector).style.display = "inline-block";
};

const setInputFieldValue = (selector: string, value: number | string) => {
  const element = memorizedQuerySelector(selector) as HTMLInputElement;
  element.value = value.toString();
  element.dispatchEvent(new KeyboardEvent("keyup"));
};

let completeOrderButtonEnabled = true;
const enableCompleteOrderButton = async () => {
  // console.log({completeOrderButtonEnabled,checkOutReady})
  if (!completeOrderButtonEnabled && checkOutReady) {
    enableElement(memorizedQuerySelector(completeOrderButtonSelector));
    completeOrderButtonEnabled = true;
  }
};
const disableCompleteOrderButton = () => {
  if (completeOrderButtonEnabled) {
    disableElement(memorizedQuerySelector(completeOrderButtonSelector));
    completeOrderButtonEnabled = false;
  }
};

const setupCompleteOrderToggler = () => {
  const gateways = document.querySelectorAll(allGatewaySelector);

  const handler: EventListener = (e) => {
    // @ts-ignore
    const gatewayId = Number(e.target?.value);
    // console.log({gatewayId,noDuesPostMAndHPayments})
    if(gatewayId){
      if (gatewayId === Number(process.env.GATEWAY_ID)) {
        if(!noDuesPostMAndHPayments){
          if(!completeOrderButtonEnabled){
            completeOrderButtonEnabled = true;
          }
          disableCompleteOrderButton();
        }
      } else {// else credit card
        // console.log("other gateway")
        if(completeOrderButtonEnabled){
          completeOrderButtonEnabled = false;
        }
        enableCompleteOrderButton();
      }
    }
  };
  gateways.forEach((gateway) => gateway.addEventListener("click", handler));
};

const setupCompanyIssuePaymentMode = async () => {
  const checkout = (await getCheckout()) as Checkout;
  const { companyIssues } = await getPaymentOptions(checkout.id);
  if (!companyIssues) {
    memorizedQuerySelector(".company-issue-block").innerHTML = `<p>Not available</p>`;
    return;
  }

  const creditCardElement = memorizedQuerySelector("[data-gateway-name=credit_card]");
  disableElement(creditCardElement);

  const input = memorizedQuerySelector(companyIssueCheckBoxSelector) as HTMLInputElement;
  memorizedQuerySelector(`${customGatewaySelector} .input-radio`).click();

  input?.addEventListener("change", (e) => {
    // @ts-ignore
    e.target?.checked ? enableGatewayPayNowButton() : disableGatewayPayNowButton();
  });

  // disablePayrollInput();
  [".allotment-container .input-group", ".payroll-container .input-group"].forEach((s) => {
    const element = memorizedQuerySelector(s);
    if (element) {
      element.innerHTML = `<p>Not available</p>`;
    }
  });
  memorizedQuerySelector(".payroll-min-container")?.remove();

  if (
    checkout.paymentDetails.length === 1 &&
    checkout.paymentDetails[0].accountType === AccountType.COMPANY_ISSUE
  ) {
    enableCompleteOrderButton();
  }

  input.checked = true;
};
// @ts-ignore
const checkoutStep = () => Shopify.Checkout.step;



if (store.currentPage.type === PageType.checkout) {
  ["page:load", "page:change"].forEach((eventName) =>
    document.addEventListener(eventName, async () => {
      const paymentElement = document.querySelector(gatewaySelector) as HTMLElement;
      // console.log({paymentElement,client:store.client})
      if (!paymentElement) return;

      

      const checkout = await getCheckout();
      
      if (!checkout || !store.customer?.id) return;
      if(checkout.readyState !== CheckoutReadyState.ready) {
        disableCompleteOrderButton();
        if (store.client){
          disablePayrollInput();
        }
      }
      else{
        checkOutReady = true;
      }
      if (!store.client) {
        console.log("Guest Checkout")
        setupCompleteOrderToggler();
        return paymentElement.remove();
      }
      addCSS(paymentElement, { display: "none" });
      // console.log({totalDue:checkout.totalDue})
      if(checkout.totalDue === 0){
        noDuesPostMAndHPayments = true;
        disableCreditCardPayment();
      }
      //  If customer belongs to any of the company
      await displayCustomPaymentGateway();
      await prefillAllowances(checkout);
      setupCompleteOrderToggler();
      await setupCompanyIssuePaymentMode();
      addCSS(paymentElement, { display: "block" });
    })
  );
  // Disable auto submit on enter
  document.onkeypress = (event) => event.keyCode !== 13;
  
  onDomLoad(async () => {
    if (store.currentPage.order.id) {
      const checkout = await getCheckoutFromOrder();
      
      await renderCheckout(checkout);

      if (checkout.paymentDetails.length) {
        const dueAmount =
          checkout.paymentDetails.find((pd) => pd.gateway === PaymentGateway.SHOPIFY)?.amount || 0;
        memorizedQuerySelector(".payment-due__price").innerHTML = formatMoney(dueAmount);
        // console.log({dueAmount});
      }
    }
  });
}
