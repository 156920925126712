import {
  disableElement,
  enableElement,
  formatMoney,
  hideGlobalProgress,
  memorizedQuerySelector,
  redirectTo,
  showGlobalProgress,
} from "../utils";
import { Store } from "../store/Store";
import {
  applyDiscountCode,
  clearDiscountCode,
  createCheckoutV2,
  getCheckout,
  getPaymentOptions,
  refreshCheckout,
} from "../store/actions";
import { Checkout } from "../interfaces";
import { LineItem } from "../interfaces/LineItem";
import { PaymentGateway } from "../enums/PaymentGateway";
import startCase from "lodash/startCase";
import { PromotionType } from "../interfaces/Promotion";
import {
  companyIssueFullFilled,
  getCompanyIssueUsage,
  hasActiveCompanyIssues,
  hasMandatoryCompanyIssueItem,
  isCompanyIssueItemAddedToCart,
} from "./companyIssues";
import { CheckoutReadyState } from "../interfaces/Checkout";
import { pollCheckout } from "./pollCheckout";
import { AxiosError, AxiosResponse } from "axios";

const completeOrderButtonSelector = "continue_button";

const paymentDetailsHtml = (checkout: Checkout) => {
  const store = Store.getInstance();
  const mhDetails = checkout.paymentDetails.filter(
    (pd) => pd.gateway === PaymentGateway.M_AND_H || Number(store.currentPage.order.id)
  );

  const details = (mhDetails || [])
    .map(
      (pd) => `
      <div class="d-flex justify-content-between mb-2">
        <div class="col-sm-8">${startCase(pd.accountType.toLowerCase())}</div>
        <div class="col-sm-4 text-end">-${formatMoney(pd.amount)}</div>
      </div>
    `
    )
    .join("");
  return checkout.paymentDetails.length
    ? `
    <div class="payment-details my-3">
      <div class="lead mb-2"><strong>Payments</strong></div>
      ${details}
    </div>    
  `
    : "";
};

// @ts-ignore
const checkoutStep = () => Shopify.Checkout.step;
// @ts-ignore
const checkoutToken = () => Shopify.Checkout.token;

const DISCOUNT_CODE_INPUT_SELECTOR = "#discount-code";
const discountBlockHtml = (checkout: Checkout) => {
  const store = Store.getInstance();
  const discountCodePromotion = checkout.promotionApplications.find(
    (pa) => pa.promotion.code?.length
  )?.promotion;

  const applyDiscountBtn = `<div name="button" class="btn apply-discount-code" title="Apply Discount" style="padding: 10px 20px;" onclick="return window._SHOPY.applyDiscount()">
    <span class="btn__content">Apply</span>
  </div>`;

  const clearDiscountBtn = `<div name="button" class="btn clear-discount-code" title="Apply Discount" style="padding: 10px 20px; background-color: #842029;" onclick="return window._SHOPY.clearDiscount()">
    <span class="btn__content">Clear</span>
  </div>`;
  const html = `
    <div class="discount-block my-3">
      <div class="col-sm-12 input-group">
        <input type="text" name="discount-code" id="discount-code" class="form-control" aria-describedby="discountCode" placeholder="Your discount code" ${
          store.currentPage.order.id ? "disabled" : ""
        }>
        ${checkout.active && !Number(store.currentPage.order.id) ? applyDiscountBtn : ""}
      </div>
      <div class="invalid-feedback">Invalid discount code.</div>
    </div>
  `;

  return html;
};

const applyDiscount = async () => {
  const code = (memorizedQuerySelector(DISCOUNT_CODE_INPUT_SELECTOR) as HTMLInputElement).value;
  if (!code?.length) {
    //  handle code error
    return;
  }
  
  const checkout = (await getCheckout()) as Checkout;
  // showGlobalProgress();
  const feedbackElement = memorizedQuerySelector(".discount-block .invalid-feedback");
  feedbackElement.style.display = "none";
  if(checkoutStep() === 'contact_information'){
    feedbackElement.style.display = "block";
    feedbackElement.innerHTML = "Apply discounts at next step.";
  }
  else{
    try {
      await applyDiscountCode(checkout.id, code, checkoutStep()).then(renderCheckout);
      await pollCheckout(checkout.id, "APPLYING DISCOUNT. DO NOT REFRESH YOUR SCREEN.");
    } catch (e: any) {
      if (e.response?.data?.message?.length || e.response?.message?.length) {
        feedbackElement.style.display = "block";
        feedbackElement.innerHTML = e.response.data?.message || e.response?.message;
      }
    } finally {
      hideGlobalProgress();
    }
  }
};
// @ts-ignore
(window._SHOPY ||= {}).applyDiscount = applyDiscount;

const clearDiscount = async (code: string) => {
  // showGlobalProgress();
  try {
    const checkout = (await getCheckout()) as unknown as Checkout;
    await clearDiscountCode(checkout.id, code, checkoutStep()).then(renderCheckout);
    await pollCheckout(checkout.id,"REMOVING DISCOUNT. DO NOT REFRESH YOUR SCREEN.");
  } catch (e) {
    console.error(e);
  } finally {
    hideGlobalProgress();
  }
};
// @ts-ignore
(window._SHOPY ||= {}).clearDiscount = clearDiscount;

const hideCIShipping = async (checkout: Checkout) => {
  const { companyIssues } = await getPaymentOptions(checkout.id);
  if (companyIssues) {
    const element = document.querySelector(".section--shipping-method");
    if (element) {
      // @ts-ignore
      // element.style.display = "none";
    }
  }
};

export const renderCheckout = async (checkout: Checkout) => {
  const store = Store.getInstance();
  const handler = async () => {
    // @ts-ignore
    if (Shopify.Checkout.step !== "payment_method") {
      showGlobalProgress();
    }
    // console.log("hasActiveCompanyIssues()?", hasActiveCompanyIssues());
    if (
      ["payment_method", "shipping_method", "contact_information"].includes(
        // @ts-ignore
        Shopify.Checkout.step
      ) &&
      hasActiveCompanyIssues()
    ) {
      console.log("hasMandatoryCI?", hasMandatoryCompanyIssueItem());
      if (hasMandatoryCompanyIssueItem()) {
        if (
          !(await getCompanyIssueUsage().then((issueUsage) => companyIssueFullFilled(issueUsage)))
        ) {
          redirectTo("/cart");
        }
      } else {
        const companyIssueUsage = await getCompanyIssueUsage();
        // console.log({companyIssueUsage,leftOverLi:companyIssueUsage.leftoverLineItems.length});
        if (
          isCompanyIssueItemAddedToCart(companyIssueUsage.issueUsage) &&
          companyIssueUsage.leftoverLineItems.length !== 0
        ) {
          redirectTo("/cart");
        }
      }
    }

    document.querySelectorAll(".product").forEach((product) => {
      const variantId = Number(product.getAttribute("data-variant-id"));
      const lineItem = checkout.lineItems.find((i) => i.variantId === variantId) as LineItem;
      const element = product.querySelector(".product__price > .order-summary__emphasis");

      if (element) {
        element.innerHTML = formatMoney(lineItem.unitDiscountedPrice * lineItem.quantity);
      }
    });

    const close = `<?xml version="1.0" encoding="iso-8859-1"?>
      <!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      \t viewBox="0 0 26 26" style="enable-background:new 0 0 26 26;" xml:space="preserve">
      <g>
      \t<path style="fill:#030104;" d="M21.125,0H4.875C2.182,0,0,2.182,0,4.875v16.25C0,23.818,2.182,26,4.875,26h16.25
      \t\tC23.818,26,26,23.818,26,21.125V4.875C26,2.182,23.818,0,21.125,0z M18.78,17.394l-1.388,1.387c-0.254,0.255-0.67,0.255-0.924,0
      \t\tL13,15.313L9.533,18.78c-0.255,0.255-0.67,0.255-0.925-0.002L7.22,17.394c-0.253-0.256-0.253-0.669,0-0.926l3.468-3.467
      \t\tL7.221,9.534c-0.254-0.256-0.254-0.672,0-0.925l1.388-1.388c0.255-0.257,0.671-0.257,0.925,0L13,10.689l3.468-3.468
      \t\tc0.255-0.257,0.671-0.257,0.924,0l1.388,1.386c0.254,0.255,0.254,0.671,0.001,0.927l-3.468,3.467l3.468,3.467
      \t\tC19.033,16.725,19.033,17.138,18.78,17.394z"/>
      </g>
      </svg>
    `;
   
    const discounts = checkout.promotionApplications
      .map((app) => {
        return `
      <div class="d-flex justify-content-between py-2">
        <div class="col-sm-6">${app.promotion.title} ${
          app.promotion.code?.length
            ? `<button type="button" class="btn-close" aria-label="Close" style="width: 16px; padding-left: 5px" onclick="window._SHOPY.clearDiscount('${app.promotion.code}')">${close}</button>`
            : ""
        } </div>
        <div class="col-sm-6 text-end">${
          app.promotion.type === PromotionType.SHIPPING_FEES && app.value === 0
            ? "<span class='order-summary__small-text'>Shipping discount will be calculated at checkout</span>"
            : "-" + formatMoney(app.value)
        }</div>
      </div>
    `;
      })
      .join("");
      const { companyIssues ,isShippingDiscount} = await getPaymentOptions(checkout.id);
      const discountsHtml = checkout.promotionApplications.length&&!isShippingDiscount
      ? `
      <hr>
      <div class="discounts-summary my-3">
        <div class="lead mb-2"><strong>Discounts</strong></div>
        ${discounts}
      </div>
    `
      : "";

    const shippingFees = companyIssues
      ? 0
      : checkout.shippingFees || Number(store.currentPage.checkout.shipping_fees);
    const shippingHtml = `
      <div class="d-flex justify-content-between my-2">
        <div class="col-sm-6">Shipping Fees</div>
        <div class="col-sm-6 text-end">${
          // @ts-ignore
          Shopify.Checkout.step === "payment_method" || store.currentPage.order.id
            ? formatMoney(shippingFees)
            : "<span class=' order-summary__small-text'>Calculated at payment</span>"
        }</div>
      </div>
    `;

    const taxesHtml = `
      <div class="d-flex justify-content-between my-2">
        <div class="col-sm-6">Taxes</div>
        <div class="col-sm-6 text-end">${
          // @ts-ignore
          Shopify.Checkout.step === "payment_method" || store.currentPage.order.id
            ? formatMoney(checkout.totalTax || Number(store.currentPage.checkout.total_tax))
            : "<span class=' order-summary__small-text'>Calculated at payment</span>"
        }</div>
      </div>
    `;

    const html = `
      <div class="shopy order-summary-section">
        <div class="container py-3">
            ${discountBlockHtml(checkout)}
            <hr />
           <div class="d-flex justify-content-between my-2">
            <div class="col-sm-8">Subtotal</div>
            <div class="col-sm-4 text-end">${formatMoney(checkout.subTotal)}</div>
          </div>
          ${shippingHtml}
          ${taxesHtml}
          ${discountsHtml}
          <hr />
          ${paymentDetailsHtml(checkout)}
          <div class="col-12 d-flex justify-content-between py-3">
            <div class="label h2">Total</div>
            <div class="" style="font-size: 24px"><span style="font-size: 12px">USD</span> ${formatMoney(
              Number(store.currentPage.order.id) ? checkout.total : checkout.totalDue
            )}</div>
          </div>
        </div>
      </div>
    `;

    document.querySelector(".order-summary-section")?.remove();
    document
      .querySelector(".order-summary__section.order-summary__section--total-lines")
      ?.insertAdjacentHTML("afterend", html);
    await hideCIShipping(checkout);
    hideGlobalProgress();
  };

  handler();
  // @ts-ignore
  // onChange(memorizedQuerySelector(".total-line__price .payment-due__price"), handler);
};

if (Store.getInstance().currentPage.path.includes("/checkouts")) {
  ["page:load"].forEach((eventName) => {
    document.addEventListener(eventName, async () => {
      try {
        const continueBtn = document.getElementById(completeOrderButtonSelector)
        // console.log({continueBtn});
        if(checkoutStep() === "shipping_method" || checkoutStep() === "payment_method"){
          if(continueBtn){
            //  disableElement(continueBtn);
             continueBtn.style.pointerEvents = "none";
             continueBtn.style.opacity = "0.5";
            //  console.log("Button disabled",{style: continueBtn.style});
          }
        }
        const checkout = await (getCheckout() as Promise<Checkout>);
        const paymentOptions = await getPaymentOptions(checkout.id);
        // if (checkoutStep() !== "shipping_method") {
        //   localStorage.setItem("pageReloaded", "false");
        // } else {
        //   if (localStorage.pageReloaded === "false") {
        //     console.log("reloading page")
        //     localStorage.setItem("pageReloaded", "true");
        //     location.reload();
        //   }
        // }
        console.log(paymentOptions);
        // if (paymentOptions.companyIssues) {
        //   if (checkoutStep() === "shipping_method") {
        //     const shippingMethodBlock = Array.from(
        //       document.getElementsByClassName("section--shipping-method")
        //     );

        //     shippingMethodBlock.map((b: any) => {
        //       const contentBoxRowCollection = Array.from(
        //         b.getElementsByClassName("content-box__row")
        //       );
        //       contentBoxRowCollection.map((r: any) => {
        //         if (!r.innerText.toLowerCase().includes("company issue")) {
        //           r.remove();
        //         }
        //       });
        //     });
        //   }
        // } else {
        //   if (checkoutStep() === "shipping_method") {
        //     const shippingMethodBlock = Array.from(
        //       document.getElementsByClassName("section--shipping-method")
        //     );

        //     shippingMethodBlock.map((b: any) => {
        //       const contentBoxRowCollection = Array.from(
        //         b.getElementsByClassName("content-box__row")
        //       );
        //       contentBoxRowCollection.map((r: any, i: any) => {
        //         console.log("Non-CI checkout...");
        //         if (r.innerText.toLowerCase().includes("company issue")) {
        //           r.getElementsByTagName("input")[0].checked = false;
        //           r.remove();
        //         } else {
        //           r.getElementsByTagName("input")[0].checked = true;
        //         }
        //       });
        //     });
        //   }
        // }
        if (checkoutStep() === "shipping_method") {
          
          const shippingMethodBlock = Array.from(
            document.getElementsByClassName("section--shipping-method")
          );

          shippingMethodBlock.map((b: any) => {
            const contentBoxRowCollection = Array.from(
              b.getElementsByClassName("content-box__row")
            );
            contentBoxRowCollection.map((r: any, i: any) => {
              
              console.log("contentBoxRowCollection",contentBoxRowCollection)
              if(paymentOptions.companyIssues){
                console.log("CI checkout...");
                if (!r.innerText.toLowerCase().includes("company issue")) {
                  r.remove();
                }
              }else if(paymentOptions.isShippingDiscount){
                console.log("Custom shipping checkout...");

                if (r.innerText.toLowerCase().includes("company issue")) {
                  r.getElementsByTagName("input")[0].checked = false;
                  r.remove();}else if (
                    r.innerText.toLowerCase().includes("fedex ground")
                  ){
                    r.getElementsByTagName("input")[0].checked = false;
                  r.remove()
                  }else{
                    r.getElementsByTagName("input")[0].checked = true;
                  }

               
              }
              else{
                console.log("Non-CI checkout...");
                if (r.innerText.toLowerCase().includes("company issue")) {
                  r.getElementsByTagName("input")[0].checked = false;
                  r.remove();
                } else if (
                  r.innerText.toLowerCase().includes("free shipping")
                ){
                  r.getElementsByTagName("input")[0].checked = false;
                r.remove()
                }else {
                  r.getElementsByTagName("input")[0].checked = true;
                }
              }
            });
          });
          if(continueBtn){
            continueBtn.style.pointerEvents = "auto";
             continueBtn.style.opacity = "1";
            //  console.log("Button enabled",{style: continueBtn.style});
            // console.log("Button enabled");
          }
        }
        
        await renderCheckout(checkout);
        console.log({readyState:checkout.readyState});
        // console.log({checkoutStep:checkoutStep(),readyState:checkout.readyState})
        // if(checkoutStep() === 'payment_method' && checkout.readyState != CheckoutReadyState.ready){
        //   console.log({task:"Adding window event listener"})
        //   window.addEventListener('beforeunload', function (e) {
        //     // Cancel the event
        //     e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        //     // Chrome requires returnValue to be set
        //     const message = "Are you sure you want to leave? The order may get cancelled.";
        //     e.returnValue = message;
        //     return message;
        //   })
        // };
        if (
          checkout.readyState === CheckoutReadyState.enqueued ||
          checkout.readyState === CheckoutReadyState.processing
        ) {
          // console.log({info:"checkout from try catch"})
          console.log({checkoutStep:checkoutStep()})
          if (checkoutStep() === 'payment_method') {
            showGlobalProgress("Please wait, we are still calculating your shipping and taxes.")
          }
          await pollCheckout(checkout.id);
          console.log("reloading page");
          location.reload();
          
        }
        else if (checkout.readyState === CheckoutReadyState.ready) {
          console.log({info:"checkout ready"})
          hideGlobalProgress();
          // if(continueBtn){
          //   continueBtn.style.pointerEvents = "auto";
          //    continueBtn.style.opacity = "1";
          //   //  console.log("Button enabled",{style: continueBtn.style});
          //   // console.log("Button enabled");
          // }
        }
        else{
          if(checkoutStep()=== null) {
            console.log("Checkout step null and ready state is errored")
            redirectTo("/cart")
          }
        }
      }catch (e: any) {
        console.log({info:"errored checkout from try catch"})
        console.error(e);
        if (e.response.status === 404) {
          await createCheckoutV2();
        }
      }
    });
  });

  document.addEventListener("page:change", async () => {
    showGlobalProgress("Please wait, we are calculating your shipping and taxes.");
    // @ts-ignore
    const checkout = await refreshCheckout(Shopify.Checkout.token, Shopify.Checkout.step);
    await renderCheckout(checkout);
    console.log("in add evt list",{readyState:checkout.readyState});
    console.log({checkoutStep:checkoutStep(),readyState:checkout.readyState})
    // if(checkoutStep() === 'payment_method' && checkout.readyState != CheckoutReadyState.ready){
    //   console.log({task:"Adding window event listener"})
    //   window.addEventListener('beforeunload', function (e) {
    //     // Cancel the event
    //     e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    //     // Chrome requires returnValue to be set
    //     const message = "Are you sure you want to leave? The order may get cancelled.";
    //     e.returnValue = message;
    //     return message;
    //   })
    // };
    if (
      checkout.readyState === CheckoutReadyState.enqueued ||
      checkout.readyState === CheckoutReadyState.processing
    ) {
      // console.log({info:"checkout from doc.addevent"})
      await pollCheckout(checkout.id);
    }
    hideGlobalProgress();
    // @ts-ignore
    // if (Shopify.Checkout.step === "payment_method") {
    //   window.location.reload();
    // } else {
    //   hideGlobalProgress();
    // }
  });
}
