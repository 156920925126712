import { getShopifyCart } from "../store/actions";
import { Store } from "../store/Store";
import partition from "lodash/partition";
import { disableElement, memorizedQuerySelector, sumBy } from "../utils";
import { memoize } from "lodash";
import { CompanyIssue } from "../interfaces/CompanyIssue";
import { config } from "../config";

export const getCompanyIssueUsage = async () => {
  const issues = Store.getInstance().customer?.companyIssues || [];
  const clientTag = Store.getInstance().client?.tag || "";
  const {
    items,
  }: {
    items: {
      variant_id: number;
      product_id: number;
      product_type: string;
      product_title: string;
      quantity: number;
      variant_options: string[];
    }[];
  } = await getShopifyCart();
  const i = 0
  // console.log({items});
  let allIssueProductTypes = issues.map((issue) => {return issue.productType.value.toLowerCase()});
  const res = issues.reduce(
    ({ issueUsage, leftoverLineItems }, issue) => {
      // console.log({issueUsage,leftoverLineItems,issue})
      const productType = issue.productType.value.toLowerCase();
      // console.log({productType,i})
      const [u, v] = partition(leftoverLineItems, (li) => {
        
        const liProductType = li.product_type?.toLowerCase() || "";
        // console.log({prod:li.product_id, q: li.quantity,i,type:liProductType,issueType: productType})
        if(clientTag && (clientTag === config.bartClient1Tag || clientTag === config.bartClient2Tag)){
          if ( liProductType === productType
            || (productType === 'pants' && liProductType === 'dresses') ||
            (productType === 'jackets' && liProductType === 'coats-outerwear') ) {
            if (issue.companyIssueIncludedProducts.length) {
              const partOfCompanyIssue = issue.companyIssueIncludedProducts.some((ip) => ip.productId === li.product_id);
              return partOfCompanyIssue;
            } else if (issue.companyIssueExcludedProducts) {
              return !issue.companyIssueExcludedProducts.some((ip) => ip.productId === li.product_id);
            } else if (issue.balance - li.quantity < 0) {
              return false;
            } else return true;
          } else return false;           
        }
        else{
          if (liProductType === productType) {
            if (issue.companyIssueIncludedProducts.length) {
              return issue.companyIssueIncludedProducts.some((ip) => ip.productId === li.product_id);
            } else if (issue.companyIssueExcludedProducts) {
              return !issue.companyIssueExcludedProducts.some((ip) => ip.productId === li.product_id);
            } else if (issue.balance - li.quantity < 0) {
              return false;
            } else return true;
          } else return false;
        }
      });
      // console.log("u:",u);
      // console.log("v:",v);
      

      // @ts-ignore
      let cartQuantity = sumBy(u, "quantity");
      // console.log({ issueUsage: issueUsage.concat({ ...issue, cartQuantity })});
      if(clientTag 
        && (clientTag === config.bartClient1Tag || clientTag === config.bartClient2Tag) 
        && cartQuantity > issue.balance){
        let actualTypeQuantity = 0;
        let virtualTypeQuantity = 0;
        // console.log({cartQuantity,balance: issue.balance})
        u.forEach((li)=>{
          const liProductType = li.product_type?.toLowerCase() || "";
          if(liProductType == productType){
            actualTypeQuantity += li.quantity;
          }
          else{
            virtualTypeQuantity += li.quantity;
          }
        })
        // console.log({actualTypeQuantity,virtualTypeQuantity})

        if( actualTypeQuantity ){
          if( virtualTypeQuantity > 0){
            let extraNonTypeProducts = actualTypeQuantity >= issue.balance ? virtualTypeQuantity :
              cartQuantity - issue.balance
            // console.log(extraNonTypeProducts);
            u.forEach((li)=>{
              const liProductType = li.product_type?.toLowerCase() || "";
              if(liProductType != productType && allIssueProductTypes.includes(liProductType)){
                const virtualCartExtraLI  = li;
                virtualCartExtraLI.quantity = li.quantity >= extraNonTypeProducts ? 
                  extraNonTypeProducts : li.quantity;
                if(extraNonTypeProducts){
                  // console.log(virtualCartExtraLI);
                  v.push(virtualCartExtraLI)
                }                
                extraNonTypeProducts = virtualCartExtraLI.quantity === extraNonTypeProducts?
                  0 : extraNonTypeProducts - li.quantity;
                // console.log({extraNonTypeProducts, actualLiQ: li.quantity, 
                //   remainingLiQ: virtualCartExtraLI.quantity
                // })
              } 
            })            
            cartQuantity = actualTypeQuantity > issue.balance ? actualTypeQuantity :
            issue.balance ;
          }else if((productType == 'dresses' && allIssueProductTypes.includes('pants')
          || (productType == 'coats-outerwear' && allIssueProductTypes.includes('jackets')) )) {
              let extraTypeProducts: number = actualTypeQuantity - issue.balance ;
              let issueBalance = issue.balance
              // console.log({extraTypeProducts,issueBalance})
              const nonSecondaryTypeItems = u.filter(
                (item) => item.product_id !== config.bartClient1Product1.id 
                      &&  item.product_id !== config.bartClient1Product2.id
                      &&  item.product_id !== config.bartClient2Product1.id 
                      &&  item.product_id !== config.bartClient2Product2.id 
                      &&  item.product_id !== config.bartClient2Product3.id  
              )
              const secondaryTypeItems = u.filter(
                  (item) => item.product_id === config.bartClient1Product1.id 
                  ||  item.product_id === config.bartClient1Product2.id
                  ||  item.product_id === config.bartClient2Product1.id 
                  ||  item.product_id === config.bartClient2Product2.id 
                  ||  item.product_id === config.bartClient2Product3.id 
              )
              const allValidItems = nonSecondaryTypeItems.concat(secondaryTypeItems);
              // console.log({allValidItems});
              allValidItems.forEach((li)=>{
                // console.log({liQuantity:li.quantity})
                if((productType == 'dresses' 
                    && (li.product_id === config.bartClient1Product1.id 
                      || li.product_id === config.bartClient2Product1.id
                    ))
                  || (productType == 'coats-outerwear' 
                    && (li.product_id === config.bartClient1Product2.id
                      || li.product_id === config.bartClient2Product2.id
                      || li.product_id === config.bartClient2Product3.id
                    )) ){
                  // console.log({liQuantitySecondary:li.quantity})
                  const excessQauntity = li.quantity <= issueBalance ? 0 : li.quantity - issueBalance ;
                  if(excessQauntity){
                    const virtualCartExtraLI  = li;
                    virtualCartExtraLI.quantity = excessQauntity;
                    issueBalance = 0;
                    // console.log(virtualCartExtraLI);
                    if(virtualCartExtraLI.quantity>0){
                      v.push(virtualCartExtraLI);
                      // console.log({cartQuantity,extraliQuantity: virtualCartExtraLI.quantity, issueBalance});
                    }
                  }else{
                    issueBalance = issueBalance > 0  ? issueBalance - li.quantity : 0;
                    // console.log({issueBalance});
                  }                
                }else{
                  issueBalance = issueBalance > 0  ? issueBalance - li.quantity : 0;
                  // console.log({issueBalance});
                }  
              })
              cartQuantity = issueBalance ? issue.balance - issueBalance : issue.balance;
              // allIssueProductTypes = allIssueProductTypes.filter((type)=>type != productType);
          }       
        }else if(virtualTypeQuantity){
          let extraNonTypeProducts = cartQuantity - issue.balance;
          let issueBalance = issue.balance;
          u.forEach((li)=>{
            const liProductType = li.product_type?.toLowerCase() || "";
            if(liProductType != productType && allIssueProductTypes.includes(liProductType)){ 
              // console.log({liQuantity:li.quantity})
              const excessQauntity = li.quantity <= issueBalance ? 0 : li.quantity - issueBalance ;
              if(excessQauntity){
                const virtualCartExtraLI  = li;
                virtualCartExtraLI.quantity = excessQauntity;
                issueBalance = 0;
                // console.log(virtualCartExtraLI);
                if(virtualCartExtraLI.quantity>0){
                  v.push(virtualCartExtraLI);
                  // console.log({cartQuantity,extraliQuantity: virtualCartExtraLI.quantity, issueBalance});
                }
              }else{
                issueBalance = issueBalance > 0  ? issueBalance - li.quantity : 0;
                // console.log({issueBalance});
              }                
            } 
          })          
          cartQuantity = virtualTypeQuantity >= issue.balance ? 
            issue.balance : issue.balance - virtualTypeQuantity ;
            // allIssueProductTypes = allIssueProductTypes.filter((type)=>type != productType);
        }
      }
      // console.log(u,v,cartQuantity)
      return { issueUsage: issueUsage.concat({ ...issue, cartQuantity }), leftoverLineItems: v };
    },
    { issueUsage: [] as any, leftoverLineItems: items }
  );
  // console.log({res});
  return res;
};

export const isCompanyIssueItemAddedToCart = (issueUsage: any) => {
  console.log({companyIssueItemsInCart:issueUsage.filter((i: any) => i.cartQuantity > 0).length})
  return issueUsage.filter((i: any) => i.cartQuantity > 0).length;
};

export const hasMandatoryCompanyIssueItem = () => {
  const issues = Store.getInstance().customer?.companyIssues || [];
  console.log({mandatoryCompanyIssueItemsInCart:issues.filter((i) => i.mandatory === true).length})
  return issues.filter((i) => i.mandatory === true).length;
};

export const companyIssueFullFilled = ({ issueUsage, leftoverLineItems }: any) => {
  const issues = Store.getInstance().customer?.companyIssues || [];
  console.log({companyIssueFullFilledCount:issueUsage.filter(
    (i: any) =>
      (i.mandatory && i.balance - i.cartQuantity !== 0) ||
      (!i.mandatory && i.balance - i.cartQuantity < 0)
  ).length})
  
  if (!issues.length || issues.every((i) => i.balance === 0)) return true;
  return (
    !leftoverLineItems.length &&
    !issueUsage.filter(
      (i: any) =>
        (i.mandatory && i.balance - i.cartQuantity !== 0) ||
        (!i.mandatory && i.balance - i.cartQuantity < 0)
    ).length
  );
};

export const hasActiveCompanyIssues = () => {
  const { client, customer } = Store.getInstance();
  // Web guest cannot use CI OR If no CI present for customer
  if (!client || !customer?.companyIssues?.length) return false;

  return customer.companyIssues.some((ci) => ci.balance > 0);
};

const invalidIcon = `<svg color="#e10000" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag-x-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zM6.854 8.146a.5.5 0 1 0-.708.708L7.293 10l-1.147 1.146a.5.5 0 0 0 .708.708L8 10.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 10l1.147-1.146a.5.5 0 0 0-.708-.708L8 9.293 6.854 8.146z"/>
</svg>`;

const checkIcon = `<svg color="green" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag-check-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
</svg>`;

export const checkCompanyIssueFulfilled = async () => {
  const { issueUsage, leftoverLineItems } = await getCompanyIssueUsage();
  return leftoverLineItems.length
    ? false
    : !issueUsage.some(
        (issue: any) =>
          issue.balance < issue.cartQuantity || issue.cartQuantity - issue.balance !== 0
      );
};

export const renderCIBalanceBanner = async () => {
  const store = Store.getInstance();
  if (!store.client || !store.customer) return;
  const { issueUsage, leftoverLineItems } = await getCompanyIssueUsage();

  if (Store.getInstance().customer.companyIssues.every((i) => !i.balance)) {
    // @ts-ignore
    document.querySelector(".shopy .ci-balance")?.innerHTML = "Company Issue: N/A";
    return;
  }

  // if (!companyIssueFullFilled({ issueUsage, leftoverLineItems })) {
  if (hasMandatoryCompanyIssueItem() || isCompanyIssueItemAddedToCart(issueUsage)) {
    if (!companyIssueFullFilled({ issueUsage, leftoverLineItems })) {
      // @ts-ignore
      document.querySelectorAll(".checkout-button").forEach(disableElement);
    }
  }

  const balanceString = issueUsage
    .map((i: CompanyIssue) => {
      // @ts-ignore
      let balance = i.balance - i.cartQuantity;
      return `<span style=""><a href="/pages/company-issue-products?product_type=${i.productType.value}">${i.productType.value}</a>: ${balance}</span>`;
    })
    .join(", ");
  const banner = document.querySelector(".shopy .ci-balance");
  if (banner) {
    banner.innerHTML =
      `<a href="/pages/company-issue-products">Company Issue:</a> ${balanceString}` as string;
  }
  // }
};


export const renderCompanyIssueTable = async () => {
  if (!Store.getInstance().client) return;
  const { issueUsage, leftoverLineItems } = await getCompanyIssueUsage();
  // console.log(issueUsage);
  if (hasMandatoryCompanyIssueItem() || isCompanyIssueItemAddedToCart(issueUsage)) {
    if (!companyIssueFullFilled({ issueUsage, leftoverLineItems })) {
      const warningHtml = `<div class="shopy"><div class="alert alert-danger" role="alert">Your Company Issued items are not in your cart. Please fulfill the required items prior to checkout. Scroll down for more details.</div></div>`;

      document
        .querySelector(".content-layout-wrapper")
        ?.insertAdjacentHTML("afterbegin", warningHtml);
    }
  }

  const issueUsageTableHtml = issueUsage
    .map((issue: any) => {
      const valid = issue.cartQuantity <= issue.balance && issue.balance - issue.cartQuantity === 0;
      if (issue.balance - issue.cartQuantity === 0) {
        return;
      }
      return `<tr>
      <td><a href="/pages/company-issue-products?product_type=${issue.productType.value}">${
        issue.productType.value
      }</a></td>
      <td>${issue.balance - issue.cartQuantity}</td>
      <td>${issue.mandatory ? "Yes" : "No"}</td>
      <td style="scale: 2">${valid ? checkIcon : invalidIcon}</td>
    </tr>`;
    })
    .filter(Boolean)
    .join("");

  const leftoverItemsHtml = `<div class="left-over-line-items" style="margin-bottom: 30px">
      <p>Items not available for Company Issue. Please check-out with required Company Issued items first, then come back and continue shopping.</p>
      <ul style="margin-left: 15px;">${leftoverLineItems
        .map(
          (item) =>
            `<li style="list-style: decimal">${item.product_title} - ${item.variant_options.join(
              "/"
            )} (${item.quantity})  <span>${invalidIcon}</span></li>`
        )
        .join("")}</ul>
    </div>
  </div>`;

  const html = `<div content="card company-issues-table shopy">
    ${
      issueUsageTableHtml?.length
        ? `
    <h3>Company Issue</h3>
    <p>Please select all remaining ‘required’ items to complete your Company Issue order. The list will adjust as you add items.</p>
    <table>
      <thead>
        <tr>
          <th>Product Type</th>
          <th>Remaining</th>
          <th>Required</th>
          <th></th>
        </tr>
      </thead>
      <tbody>${issueUsageTableHtml}</tbody>
    </table>`
        : ""
    }
    
    ${leftoverLineItems.length ? leftoverItemsHtml : ""}
  </div>`;

  document.querySelector(".content-layout-wrapper")?.insertAdjacentHTML("beforeend", html);
};
