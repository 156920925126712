import memorize from "lodash/memoize";

export const formatMoney = (amount: number) => {
  const amt = amount > 0 ? amount : 0;
  return "$" + (amt / 100).toFixed(2);
};

export const redirectTo = (url: string) => setTimeout(() => (window.location.href = url), 500);

export const onDomLoad = (fn: () => unknown) => {
  if (document.readyState === "complete" || document.readyState === "interactive") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
};

export const addCSS = (element: HTMLElement, styles: { [key: string]: string }) => {
  // @ts-ignore
  Object.keys(styles).forEach((key: string) => (element.style[key] = styles[key]));
};

export const memorizedQuerySelector = memorize(
  (selector: string) => document.querySelector(selector) as HTMLElement
);

export const showGlobalProgress = (message = "Loading...") => {
  const loaderHtml = `
    <div class="shopy loader-container">
      <div  class="loader">
        <div class="spinner spinner-border mb-2" role="status">
          <span class="visually-hidden">${message}</span>
        </div>
        <div class="message">${message}</div>
      </div>
    </div>
  `;
  document.querySelector("body")?.insertAdjacentHTML("beforeend", loaderHtml);
  memorizedQuerySelector("body").classList.add("stop-scrolling");
};

export const hideGlobalProgress = () => {
  memorizedQuerySelector("body").classList.remove("stop-scrolling");
  document.querySelector(".shopy.loader-container")?.remove();
};

export const disableElement = (element: HTMLElement) =>
  addCSS(element, {
    pointerEvents: "none",
    opacity: " 0.5",
  });

export const enableElement = (element: HTMLElement) =>
  addCSS(element, {
    pointerEvents: "auto",
    opacity: "1",
  });

export const clearAllWithSelector = (selector: string) =>
  document.querySelectorAll(selector).forEach((node) => (node.innerHTML = ""));

export const setWithSelector = (selector: string, html: string, parent?: Element) => {
  const element = (parent || document).querySelector(selector);
  if (element) {
    element.innerHTML = html;
    return true;
  } else return false;
};

export const onChange = (
  element: Element,
  callback: () => void,
  observerConfig: MutationObserverInit = {
    childList: true,
    subtree: true,
  }
) => {
  const observer = new MutationObserver((mutations, observer) => {
    observer.disconnect();
    callback();
    observer.observe(element, observerConfig);
  });
  observer.observe(element, observerConfig);
  return observer;
};

export const checkoutBtn = `<button onclick="return window._SHOPY.checkout(event)" class="checkout-button checkout button alt wc-forward checkoutbtn" style="width: 100%; display: block;">Checkout</button>`;

export const sumBy = <T>(list: T[], field: keyof T): number => {
  return (list || []).reduce((sum, item) => sum + Number(item[field]), 0);
};
