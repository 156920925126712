export const config = {
    env: process.env.NODE_ENV || "development",

    // Bart Client 1 details here
    bartClient1Id: process.env.NODE_ENV === "production" ? 58 : // Bart Station Agent
    process.env.NODE_ENV === "staging" ? 1 : 0, // AAL in Statging(Dev)

    bartClient1Tag: process.env.NODE_ENV === "production" ? "BS" : // Bart Station Agent
    process.env.NODE_ENV === "staging" ? "AAL" : "", // AAL in Statging(Dev)

    bartClient1Product1 : {
        id: process.env.NODE_ENV === "production" ? 4647053918257 : // Mikayla Navy Dress
            process.env.NODE_ENV === "staging" ? 6602275619013 : 0, // Mikayla Navy Dress
        actualType: process.env.NODE_ENV === "production" ? 27 : // Dresses
            process.env.NODE_ENV === "staging" ? 27 : 0, // Dresses
        secondaryType: process.env.NODE_ENV === "production" ? 6 : // Pants
            process.env.NODE_ENV === "staging" ? 7 : 0, // Pants
    },
    bartClient1Product2 : {
        id: process.env.NODE_ENV === "production" ? 4776984477745 : // Unisex Softshell Jacket Navy
            process.env.NODE_ENV === "staging" ? 6602272964805 : 0, // Leather Black Jacket
        actualType: process.env.NODE_ENV === "production" ? 18 : // Coats-Outerwear
            process.env.NODE_ENV === "staging" ?  19 : 0, // Coats-Outerwear
        secondaryType: process.env.NODE_ENV === "production" ? 10 : // Jackets
            process.env.NODE_ENV === "staging" ?  11 : 0, // Jackets
    },

    // Bart Client 2 details here, Not needed in Dev/Staging
    bartClient2Id: process.env.NODE_ENV === "production" ? 59 : 0, // Bart Foreworker
    bartClient2Tag: process.env.NODE_ENV === "production" ? "BF" : 0,// Bart Foreworker

    bartClient2Product1 : { 
        id: process.env.NODE_ENV === "production" ? 7216344563905 : 0, // Mikayla Black Dress
        actualType: process.env.NODE_ENV === "production" ? 27 : 0,
        secondaryType: process.env.NODE_ENV === "production" ? 6 : 0,
    },
    bartClient2Product2 : {
        id: process.env.NODE_ENV === "production" ? 7225067897025 : 0, // Female Soft Shell Black
        actualType: process.env.NODE_ENV === "production" ? 18 : 0,
        secondaryType: process.env.NODE_ENV === "production" ? 10 : 0,
    },
    bartClient2Product3 : {
        id: process.env.NODE_ENV === "production" ? 7225067962561 : 0, // Male Soft Shell Black
        actualType: process.env.NODE_ENV === "production" ? 18 : 0,
        secondaryType: process.env.NODE_ENV === "production" ? 10 : 0,
    },
    jacketRankings : process.env.NODE_ENV === "production" ? "striping-for-uniform-jacket" : "jacket-rank" // product handle diff
};

console.log({config});
