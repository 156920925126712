import { PaymentDetail } from "./PaymentDetail";
import { LineItem } from "./LineItem";
import { PromotionApplication } from "./PromotionApplication";

export enum CheckoutReadyState {
  enqueued = "enqueued",
  ready = "ready",
  errored = "errored",
  processing = "processing",
}

export interface Checkout {
  id: number;
  token: string;
  url: string;
  customerId: number;
  amount: number;
  lineItems: LineItem[];
  paymentDetails: PaymentDetail[];
  totalTax: number;
  subTotal: number;
  totalDue: number;
  shippingFees: number;
  promotionApplications: PromotionApplication[];
  active: boolean;
  total: number;
  readyState: CheckoutReadyState | null;
}
