import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (["staging", "production"].includes(process.env.NODE_ENV as string)) {
  Sentry.init({
    dsn: "https://ccd9bc5dfb9a4c74a4f16e36ce88ed3a@sentry.wafer.ee/4",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
