import { ICart, ICartLineItem, ICheckout, ICollection, IOrder, IProduct } from "shopify-api-node";
import { Checkout, Client, Collection, Customer ,PurchaseStatus ,SessionStatus} from "../interfaces";
import { PageType } from "../enums/PageType";
import * as Sentry from "@sentry/browser";
import {  } from "../interfaces/SessionStatus";

const JSON_DATA_SELECTOR = "shopy-platform-data";

export class Store {
  domain: string;
  permanentDomain: string;
  url: string;
  webGuestCollection: Collection;
  visitorCollection: Collection;
  storeClients: Client[];
  client: Client | undefined;
  currentPage: {
    type: PageType;
    hmac: string;
    path: string;
    template: string | undefined;
    cart: ICart & { items: ICartLineItem[] };
    collection: ICollection;
    checkout: ICheckout & { shipping_fees: string };
    product: IProduct;
    order: IOrder;
  };
  checkout?: Checkout;
  customer: Customer;
  purchase_status:PurchaseStatus;
  session_status:SessionStatus;


  constructor() {
    const data = JSON.parse(document.getElementById(JSON_DATA_SELECTOR)?.innerHTML || "{}");
    this.domain = data.shop.domain;
    this.permanentDomain = data.shop.permanent_domain;
    this.url = data.shop.url;
    this.webGuestCollection = data.shop.web_guest_collection;
    this.visitorCollection = data.shop.visitor_collection;
    this.storeClients = data.clients;
    this.customer = data.customer;
    this.purchase_status=data.purchase_status;
    this.session_status=data.session_status;
    if (this.customer) {
      this.customer.tags = data.customer_tags;
    }

    this.currentPage = {
      type: PageType.other,
      path: data.current_page.path,
      template: data.current_page.template || undefined,
      cart: data.current_page.cart,
      collection: data.current_page.collection,
      checkout: data.current_page.checkout,
      product: data.current_page.product,
      order: data.current_page.order,
      hmac: data.current_page.hmac,
    };
    this.client = this.storeClients.find(
      (client) => (this.customer?.tags || []).findIndex((tag) => tag === client.tag) > -1
    );
    // console.log(this.client,this.client?.tag);
    // console.log(this.customer,this.customer?.tags);
    if (this.currentPage.product) {
      this.currentPage.type = PageType.product;
    } else if (this.currentPage.collection) {
      this.currentPage.type = PageType.collection;
    } else if (this.currentPage.checkout?.id) {
      this.currentPage.type = PageType.checkout;
    }
    // TODO: set only essential data from store
    Sentry.setExtra("store", this);
  }

  private static instance: Store;
  static getInstance() {
    return (Store.instance ||= new Store());
  }
}

// @ts-ignore
window.Store = Store;