import { Client } from "./Client";

export enum PromotionType {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED",
  BUY_X_GET_Y = "BUY_X_GET_Y",
  SHIPPING_FEES = "SHIPPING_FEES",
}

export enum PromotionAllocationMethod {
  ACROSS = "ACROSS",
  EACH = "EACH",
}

export interface Promotion {
  title: string;
  clientId: number;
  active: boolean;
  type: PromotionType;
  value: number;
  startsAt: string;
  endsAt: string;
  code: string;
  oncePerCustomer: number;
  allocationMethod: PromotionAllocationMethod;
}
